<template>
  <div id="app">
    <Header v-if="excludeRoutes.indexOf($route.name) == -1"></Header>
    <router-view v-if="isRouterAlive"/>
    <Footer v-if="excludeRoutesFooter.indexOf($route.name) == -1"></Footer>
  </div>
</template>

<script>
  import Header from '@/pages/header/Header';
  import Footer from '@/pages/footer/Footer';
export default {
  name: 'App',
  components: {
      Header,
      Footer
  },
  mounted() {
      // console.log(this.$route.name)
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  data () {
    return {
      excludeRoutes: [
        'login',
        'register',
        'companydetail',
        'jobdetail',
        'resumecontent',
        'forget',
        'joblist',
        'profile',
        'applycontent',
        'securitycontent',
        'notificationprivacy',
        'articledetail',
        'articlelist'], //用不到头部和脚部的页面
      excludeRoutesFooter: [
        'login',
        'register',
        'companydetail',
        'jobdetail',
        'resumecontent',
        'forget',
        'joblist',
        'profile',
        'applycontent',
        'securitycontent',
        'notificationprivacy'],
      isRouterAlive: true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function () {
          this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="scss">
  #app {
      @include background_color($light_background_color);
  }
</style>
