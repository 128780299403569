/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import store from '@/store/index';
import { AccountLogin,AccountLogout } from '@/api/account';
import { Modal } from 'view-design';


export default {
    namespaced: true,
    actions: {
        /**
         * @description 登录
         * @param {Object} param context
         * @param {Object} param user_name {String} 用户账号
         * @param {Object} param password {String} 密码
         * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
         */
        login({ dispatch }, {
            user_name = '',
            password = ''
        } = {}) {
            return new Promise((resolve, reject) => {
                // 开始请求登录接口
                AccountLogin({
                    user_name,
                    password
                })
                    .then(async res => {
                        console.log(res)
                        // 设置 cookie 一定要存 user_id 和 token 两个 cookie
                        // 整个系统依赖这两个数据进行校验和存储
                        // user_id 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
                        // token 代表用户当前登录状态 建议在网络请求中携带 token
                        // 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
                        // 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
                        util.cookies.set('user_id', res.data.user_id);
                        util.cookies.set('app_token', res.data.app_token);
                        // 设置 vuex 用户信息
                        // await dispatch('front/user/set', res.data, { root: true });
                        // // // 用户登录后从持久化数据加载一系列的设置
                        // await dispatch('load');

                        // 结束
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },

        /**
         * @description 退出登录
         * */
        logout({ commit, dispatch }, { confirm = false, vm } = {}) {
            async function logout() {
                const app_token = util.cookies.get('app_token');
                AccountLogout({
                    app_token:app_token
                }).then((res) => {
                    localStorage.clear();
                    sessionStorage.clear()
                    util.cookies.remove('app_token')
                    util.cookies.remove('user_id')
                    // 删除localStorage
                    store.dispatch('front/db/databaseClear', {
                        user: true
                    });
                    // 清空 vuex 用户信息
                    dispatch('front/user/set', {}, { root: true });
                    // 跳转路由
                    router.push({
                        name: 'Login'
                    });
                }).catch(() => {
                })
            }
            if (confirm) {
                Modal.confirm({
                    title: '退出登录',
                    content: '确定退出登录吗？',
                    onOk() {
                        logout();
                    }
                });
            } else {
                logout();
            }
        },

        /**
         * @description 用户登录后从持久化数据加载一系列的设置
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         */
        load({ state, dispatch }) {
            return new Promise(async resolve => {
                // 加载用户登录信息
                await dispatch('front/user/load', null, { root: true });
                // end
                resolve();
            })
        },

    }
};
