import Vue from 'vue';
import App from './App.vue'
import router from "./router";
import store from '@/store/index';

import 'normalize.css/normalize.css'
import "./assets/styles/theme.less";

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.prototype.Bus = new Vue();

import ElementUI  from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber);

import $ from 'jquery';
Vue.prototype.$ = $;

// 懒加载
// import VueLazyload from 'vue-lazyload';
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: require('./assets/images/no.png'),
//   loading: require('./assets/images/moren.jpg'),
//   attempt: 1,
//   listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
// });

import ViewUI from "view-design";
Vue.use(ViewUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
