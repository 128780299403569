<template>
    <div class="container">
        <div class="head">
            <div style="display: flex">
                <img src="@/assets/images/logo.png" width="210" height="80" class="wow fadeIn" data-wow-delay="0.4s" style="margin-top: 10px;">
                <div style="margin-left: 30px;">
                    <ul class="nav-bar">
                        <li class="menu-item active wow fadeInDown" :data-wow-delay="'0.'+index+'s'"
                            v-for="(item, index) in navList"
                            :class="currentIndex === index ? 'curr' : ''"
                            @click="changeCurr(index)"
                            :key="index"
                        >
                            <p>{{ item.title }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div style="display: flex">
                <div class="majorItem hover-pointer">
                    <p class="wow fadeInUp" data-wow-delay="0.6s" @click="redirect('/ResumeContent')">上传简历</p>
                    <p class="wow fadeInUp" data-wow-delay="0.8s" @click="navTo('/joblist')">我要找工作</p>
                    <p class="wow fadeInUp" data-wow-delay="1s">我要招聘</p>
                </div>
                <div class="loginbtn hover-pointer">
                    <p @click="navTo('/login')" class="wow fadeInRight hover-pointer" data-wow-delay="0.8s" v-show="!userInfo.user_name">
                        <Icon type="md-person"/>
                        登录
                    </p>
                    <el-dropdown v-show="!!userInfo.user_name">
                         <p class="el-dropdown-link wow fadeInRight" data-wow-delay="0.8s">
                          {{ userInfo.user_name}}<i class="el-icon-arrow-down el-icon--right"></i>
                         </p>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div @click="redirect('/ResumeContent')">
                                    我的简历
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item divided>
                                <div @click="redirect('/Profile')">
                                    个人中心
                                </div>
                            </el-dropdown-item>
<!--                            <el-dropdown-item divided>-->
<!--                                <div @click="redirect('/SecurityContent')">-->
<!--                                    账号与安全中心-->
<!--                                </div>-->
<!--                            </el-dropdown-item>-->
<!--                            <el-dropdown-item divided>-->
<!--                                <div @click="redirect('/NotificationPrivacy')">-->
<!--                                    通知与隐私设置-->
<!--                                </div>-->
<!--                            </el-dropdown-item>-->
                            <el-dropdown-item divided>
                                <div @click="redirect('/ApplyContent')">
                                    求职记录
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <p class="wow fadeInRight" data-wow-delay="0.7s">|</p>
                    <p @click="navTo('/register')" class="navbar-btn nav-button wow fadeInRight hover-pointer"
                       data-wow-delay="0.6s" v-show="!userInfo.user_name">注册</p>
                    <p @click="logout" class="navbar-btn nav-button wow fadeInRight hover-pointer" data-wow-delay="0.6s"
                       v-show="!!userInfo.user_name">退出</p>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="bg">
                <div class="centerbox">
                    <div class="center">
                        <div>

                        </div>
                        <div style="margin-left: 20px;">

                        </div>
                        <div>

                        </div>
                    </div>
                </div>
                <div class="centerbottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import storage from '@/utils/storage.js'
    import {WOW} from 'wowjs';

    export default {
        name: "Header",
        data() {
            return {
                userInfo: {},
                currentIndex: 0, // 当前分类下标
                navList: [{"title": "首页"}, {"title": "职位"}, {"title": "企业"},
                    {"title": "校招"},
                    {"title": "职场资讯"}],
            };
        },
        mounted() {
            var that = this;
            if (storage.getItem('userInfo')) {
                that.userInfo = JSON.parse(storage.getItem('userInfo'));
            }
            new WOW({
                live: false
            });
            new WOW().init();
        },
        methods: {
            changeCurr(index) {
                this.currentIndex = index;
                this.Bus.$emit('scrollToVIew', index);
            },
            // 修改密码
            navTo(path) {
                this.$router.push(path);
            },
            logout() {
                var that = this;
                storage.removeItem('user_id');
                storage.removeItem('app_token');
                storage.removeItem('userInfo');
                that.userInfo = {};
                this.$Message.success('退出成功');
                that.$router.push('/');
            },
            redirect(path){
                if (this.userInfo.user_name) {
                    this.$router.push({ path: path });
                } else {
                    this.$Modal.confirm({
                        title: '请登录',
                        content: '<p>请登录后执行此操作</p>',
                        okText: '立即登录',
                        cancelText: '继续浏览',
                        onOk: () => {
                            this.$router.push({
                                path: '/login',
                            });
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @import '../../assets/css/animate.css';

    .container {
        width: 100%;
        height: 260px;
        z-index: 99;
        background-color: white;
        .head {
            width: 1200px;
            height: 100px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .nav-bar {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100px;
                align-items: center;
                position: relative;
                li {
                    padding: 10px 20px;
                    text-align: center;
                    letter-spacing: 2px;
                    p:nth-child(1) {
                        font-size: 16px;
                        font-weight: bold;
                        color: #3F3F3F;
                        padding: 0 5px;
                    }
                    &:hover {
                        p {
                            color: $primary_color;
                        }
                        cursor: pointer;
                    }
                }
                .curr {
                    p:nth-child(1) {
                        border-bottom: 2px solid $primary_color;
                        color: $primary_color;
                    }
                }
            }

            .majorItem {
                display: flex;
                align-items: center;
                height: 100px;
                p {
                    margin-left: 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                }
                p:nth-child(2) {
                    color: $primary_color;
                }
            }

            .loginbtn {
                margin-left: 20px;
                display: flex;
                align-items: center;
                height: 100px;
                color: #333333;

                p {
                    margin-left: 10px;
                }
            }
        }

        .bottom {
            width: 100%;
            height: 160px;
            .bg {
                height: 140px;
                background-color: #3EA2FA;
                .centerbox {
                    width: 1200px;
                    margin: 0 auto;
                    height: 140px;
                    position: relative;
                    .center {
                        position: absolute;
                        width: 1200px;
                        height: 160px;
                        top: -20px;
                        background: url("@/assets/images/index_line_bg.png") no-repeat;
                    }
                }
                .centerbottom {
                    width: 100%;
                    height: 20px;
                    background-color: $light_background_color;
                }
            }
        }
    }
</style>
