import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/**
 * 解决重复点击菜单会控制台报错bug
 */
 const routerPush = Router.prototype.push
 Router.prototype.push = function push(location) {
   return routerPush.call(this, location).catch(error=> error)
 }

export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/", // 首页
      name: "Index",
      component: () => import('@/pages/HomeIndex'),
    },
    {
      path: "/Login", // 登陆
      name: "login",
      component: () => import('@/pages/Login'),
      meta: {
        title: "登录",
      },
    },
    {
      path: "/Register", // 注册
      name: "register",
      component: () => import('@/pages/Register'),
      meta: {
        title: "注册",
      },
    },
    {
      path: "/Forget", // 登陆
      name: "forget",
      component: () => import('@/pages/Forget'),
      meta: {
        title: "找回密码",
      },
    },
    {
      path: "/CompanyDetail",
      name: "companydetail",
      component: () => import('@/pages/CompanyDetail'),
      meta: {
        title: "公司详情",
      },
    },
    {
      path: "/JobDetail",
      name: "jobdetail",
      component: () => import('@/pages/JobDetail'),
      meta: {
        title: "职位详情",
      },
    },
    {
      path: "/JobList",
      name: "joblist",
      component: () => import('@/pages/JobList'),
      meta: {
        title: "职位列表",
      },
    },
    {
      path: "/ResumeContent",
      name: "resumecontent",
      component: () => import('@/pages/ResumeContent')
    },
    {
      path: "/ApplyContent",
      name: "applycontent",
      component: () => import('@/pages/user/ApplyContent')
    },
    {
      path: "/SecurityContent",
      name: "securitycontent",
      component: () => import('@/pages/user/SecurityContent')
    },
    {
      path: "/NotificationPrivacy",
      name: "notificationprivacy",
      component: () => import('@/pages/user/NotificationPrivacy')
    },
    {
      path: "/Profile",
      name: "profile",
      component: () => import('@/pages/user/Profile')
    },
    {
      path: "/ArticleList",
      name: "articlelist",
      component: () => import('@/pages/ArticleList')
    },
    {
      path: "/ArticleDetail",
      name: "articledetail",
      component: () => import('@/pages/ArticleDetail')
    },
  ],
});
