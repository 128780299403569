/**
 * 用户信息
 * */
import storage from '@/utils/storage';
export default {
    namespaced: true,
    state: {
        // 用户信息
        userInfo: {
            username: '',
            app_token:'',
            email: '',
            headimg: '',
            mobile_phone: '',
            user_id: 0
        },
    },
    mutations: {

    },
    actions: {

        /**
         * @description 设置用户数据
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         * @param {*} info info
         */
        set ({ state, dispatch }, info) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                // store 赋值
                state.info = info;
                storage.setItem('userInfo', info);
                // 持久化
                await dispatch('front/db/set', {
                    dbName: 'sys',
                    path: 'user.userInfo',
                    value: info,
                    user: true
                }, { root: true });
                // end
                resolve();
            })
        },
        /**
         * @description 从数据库取用户数据
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         */
        load ({ state, dispatch }) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                // store 赋值
                state.info = await dispatch('front/db/get', {
                    dbName: 'sys',
                    path: 'user.userInfo',
                    defaultValue: {},
                    user: true
                }, { root: true });
                // end
                resolve();
            })
        }
    }
}
