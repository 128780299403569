
import request from '@/plugins/request';

/*
 * 登录
 * */
export function AccountLogin (data) {
    return request({
        url: 'users/login/login',
        method: 'post',
        data
    });
}

/**
 * 退出登陆
 * @constructor
 */
export function AccountLogout (data) {
    return request({
        url: 'users/login/login_out',
        method: 'post',
        data
    });
}

/**
 * @description 设置 短信设置 发送验证码
 * @param {Object} param data {Object} 传值参数
 */
export function captchaApi (data) {
    return request({
        url: 'users/Share/get_code',
        method: 'post',
        data
    });
}

/**
 * @description 设置 短信设置 注册
 * @param {Object} param data {Object} 传值参数
 */
export function registerApi (data) {
    return request({
        url: 'users/login/register',
        method: 'post',
        data
    });
}

/**
 * @description 设置 短信 修改密码
 */
export function serveModifyApi (data) {
    return request({
        url: 'users/Share/retrieve_pwd',
        method: 'post',
        data
    });
}
